<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            {{ $t("studentGroups.title") }}
          </template>
          <template v-slot:toolbar>
          </template>
          <template v-slot:body>
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    {{ $t("studentGroups.list.name") }}
                  </th>
                  <th class="text-left">
                    {{ $t("studentGroups.list.level") }}
                  </th>
                  <th class="text-left">
                    {{ $t("studentGroups.list.year") }}
                  </th>
                  <th class="text-left">Воспитатель</th>
                  <th class="text-left"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in groups" :key="item.id">
                  <td>{{ item.groupName }}</td>
                  <td>{{ dictionariesService.preschoolLevelName(item.level) }}</td>
                  <td>{{ item.educationYearString }}</td>
                  <td>{{ item.classroomTeacherName }}</td>
                  <td>
                    <router-link :to="{ name: 'PreschoolGroupInfo', params: { id: item.id }}" class="btn btn-success" style="margin-left: 10px">
                      Детали
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";

export default {
  name: "KindergartenerDashboard",
  computed: {
    dictionariesService() {
      return DictionariesService
    }
  },
  components: {
    KTPortlet
  },
  data() {
    return {
      preschoolId: null,
      groups: null,
      show: true,
      levels: DictionariesService.preschoolLevels(),
      educationYears: [{text: "2023-2024", value: 2023}],
      kindergarteners: []
    };
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      let $this = this;
      ApiService.querySecured("preschoolGroups/kindergartenerGroups").then(({data}) => {
        $this.groups = data.list;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    openGroupInfo(group) {
      this.$router.push({name: "groupInfo", params: {groupId: group.id}});
    }
  }
};
</script>
